<template>
  <div class="main-newlist hear-menu">
    <a-sub-menu :key="menuInfo.id" v-bind="$props" v-on="$listeners">
      <span slot="title" class="header-sencend-child-padding">
        <i></i>
        <span :class="{'header-sencend-child-class-fon20':menuInfo.themename === '本草文化工程'}">{{ menuInfo.themename }}</span>
      </span>
      <template v-for="item in menuInfo.childrenList">
        <a-menu-item v-if="!item.childrenList" :key="item.id">
          <!-- <span>{{ item.themename }}</span> -->
          <div class="menu-style-list" :title="item.themename">
            {{ item.themename }}
          </div>
        </a-menu-item>
        <sub-menu v-else :key="item.id" :menu-info="item" />
      </template>
    </a-sub-menu>
  </div>
</template>
<script>
import { Menu } from "ant-design-vue";
export default {
  name: "SubMenu",
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      routerName: "",
    };
  },
  methods: {},
};
</script>
<style lang="less">
@import "./hearMenu.less";
</style>
