<template>
  <div class="news-list">
    <div class="page-title">
      <div class="container">
        <div class="con-title con-title-white">
          <span>联系我们</span>
        </div>
      </div>
    </div>
    <div class="container">
      <news-list
        :titleList="titleList"
        :dataList="dataList"
        :defaultTitle="defaultTitle"
        :total="total"
        :current="current"
        :content="content"
        :titleName="titleName"
        :fileList="fileList"
        routerName="ContactDetails"
        title="联系我们"
        @getChangeId="getChangeId"
        @changePage="changePage"
      ></news-list>
    </div>
  </div>
</template>

<script>
import newsList from "@/views/components/newsList.vue";
import * as api from "@/services/newsList.js";
export default {
  name: "BrandIndexDetails",
  components: { newsList },
  data() {
    return {
      titleList: [],
      dataList: [],
      defaultTitle: [],
      total: 0,
      current: 1,
      content: "",
      titleName: "",
      fileList: [],
    };
  },
  created() {
    //查询新闻栏目列表
    this.getAllLevelTheme();
  },
  methods: {
    changePage(number) {
      this.current = number;
      this.getNoteList(this.defaultTitle[0]);
    },
    getChangeId(id) {
      if (id === "7.3.") {
        //下载专区
        this.$router.push({
          name: "DownLoadEar",
          params: {
            id: "7.",
          },
        });
        return;
      }
      this.defaultTitle = [id];
      this.current = 1;
      this.getNoteList(id);
    },
    getAllLevelTheme() {
      if (this.titleList && this.titleList.length > 0) {
        if (!this.$route.params.id) {
          this.defaultTitle = [this.titleList[0].id];
        } else {
          this.defaultTitle = [this.$route.params.id];
        }
        this.current = 1;
        //查询当前栏目下的新闻列表
        this.getNoteList(this.defaultTitle);
      } else {
        api.getAllLevelTheme("7.").then((res) => {
          if (res.data.success) {
            this.titleList = res.data.data;
            if (!this.$route.params.id) {
              this.defaultTitle = [this.titleList[0].id];
            } else {
              this.defaultTitle = [this.$route.params.id];
            }
            this.current = 1;
            //查询当前栏目下的新闻列表
            this.getNoteList(this.defaultTitle);
          }
        });
      }
    },
    getNoteList(id) {
      let obj = {
        themeId: id,
        pageNumber: this.current,
        pageSize: 10,
      };
      api.getNoteList(obj).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.data.list;
          this.total = Number(res.data.data.count);
          this.content = "";
          this.titleName = "";
          if (this.dataList.length === 1) {
            this.fileList = []
            api.getNoteContentByNoteId(this.dataList[0].id).then((res) => {
              if (res.data.success) {
                this.content = res.data.data.content;
                this.titleName = res.data.data.title;
                if (
                  res.data.map &&
                  res.data.map.attachmentList &&
                  res.data.map.attachmentList.length > 0
                ) {
                  this.fileList = res.data.map.attachmentList;
                }
              } else {
                this.$message.error("查询失败！");
              }
            });
          }
        }
      });
    },
    onChange(pageNumber) {
      console.log("Page: ", pageNumber);
    },
  },
};
</script>
<style lang="less">
@import "../journal/journalIndex.less";
</style>
