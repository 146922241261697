<template>
  <div class="con-item">
    <div class="container">
      <div class="news-menu">
        <a-card :title="title" :bordered="false">
          <a-menu
            :selectedKeys="defaultTitle"
            :defaultOpenKeys="['8.5.']"
            theme="light"
            mode="inline"
            @select="changeMenu"
          >
            <template v-for="item in titleList">
              <a-menu-item v-if="!item.childrenList" :key="item.id">
                <i></i>
                <span>{{ item.themename }}</span>
              </a-menu-item>
              <Sub-menu v-else :key="item.id" :menu-info="item" />
            </template>
          </a-menu>
        </a-card>
      </div>
      <div
        class="list"
        v-if="themeName === '友情链接' || themeName === '战略合作'"
      >
        <a-list
          :grid="{ column: 1 }"
          :data-source="dataList"
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a-card :bordered="false">
              <template slot="title">
                <i></i>
                <a @click="goOther(item.imgurl)"  :title="item.title">{{
                  item.title
                }}</a>
              </template>
              <template slot="extra"><img @click="goOther(item.imgurl)" :src="item.imgurlPic" alt=""></template>
            </a-card>
          </a-list-item>
        </a-list>
        <a-pagination
          show-quick-jumper
          v-show="total > 10"
          :default-current="current"
          :total="total"
          @change="onChange"
        />
      </div>
      <div
        class="list"
        v-if="themeName !== '友情链接' && themeName !== '战略合作'"
      >
        <a-list
          :grid="{ column: 1 }"
          :data-source="dataList"
          v-if="dataList.length > 1 || dataList.length === 0"
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a-card :bordered="false">
              <template slot="title">
                <i></i>
                <a @click="goDetails(item.id)" :title="item.title">{{
                  item.title
                }}</a>
              </template>
              <template slot="extra">{{ item.pubdate }}</template>
            </a-card>
          </a-list-item>
        </a-list>
        <div v-if="dataList.length === 1">
          <div>
            <!-- <h3 style="text-align: center">{{ titleName }}</h3> -->
            <div
              class="class-buffer ql-container ql-snow font16"
            >
              <div class="brief-introduction ql-editor">
                <div class="brief-introduction-content" v-html="content"></div>
              </div>
              <div class="l-attahment">
                <span class="xb-attahment" v-if="fileList.length > 0"
                  >附件：</span
                >
                <div
                  class="attahment-name"
                  v-for="(item, index) in fileList"
                  :key="index"
                  @click="goDown(item, index)"
                >
                  <a-icon type="file" />{{ item.name
                  }}<a style="margin-left: 20px">
                    <a-icon type="download" />下载
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-pagination
          show-quick-jumper
          v-show="total > 10"
          :default-current="current"
          :total="total"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from "./hearMenu.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "NewsList",
  components: { SubMenu },
  props: {
    titleList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dataList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    fileList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultTitle: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    titleName: {
      type: String,
      default: "",
    },
    routerName: {
      type: String,
      default: "",
    },
    themeName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelValue: [],
    };
  },
  created() {},
  methods: {
    goOther(url) {
      window.open(url)
    },
    goDown(item) {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL +
        "/download?fileCode=" +
        item.sysFldFilepath +
        "&downloadName=" +
        item.name;
    },
    //查看新闻详情
    goDetails(doi) {
      this.$router.push({ name: this.routerName, params: { doi: doi } });
    },
    onChange(number) {
      this.$emit("changePage", number);
    },
    changeMenu(item) {
      this.$emit("getChangeId", item.key);
    },
  },
};
</script>
<style lang="less">
@import "../news/NewsList.less";
@import "../QuillEditor/quill-editor.less";
</style>
